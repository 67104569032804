import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "../Components/Button";
import Content from "../Components/Content";
import MainContainer from "../Components/MainContainer";
import SecondaryButton from "../Components/SecondaryButton";
import Title from "../Components/Title";

const InfoRow = styled.div`
    display: flex;
    flex-direction: row;
    font-size: 2.4rem;
    margin-bottom: 32px;
    & label {
        margin-right: 32px;
    }
    & p {
        margin: 0;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 32px;
    & div {
        width: 240px;
    }
`;

const Appointment = () => {
    const navigate = useNavigate();

    const goToHome = () => {
        navigate("/Home");
    };

    const goToConfirm = () => {
        navigate("/Confirm");
    };

    return <MainContainer>
        <Content>
            <Title>Please confirm your appointment</Title>
            <InfoRow>
                <label>Forename:</label>
                <p>William Green</p>
            </InfoRow>
            <InfoRow>
                <label>Appointment date:</label>
                <p>{(new Date()).toLocaleDateString("en-GB", {day: 'numeric', month: 'long', year: 'numeric'})}</p>
            </InfoRow>
            <InfoRow>
                <label>Appointment time:</label>
                <p>11:45</p>
            </InfoRow>
            <ButtonContainer>
                <div>
                    <SecondaryButton text="Cancel" onClick={goToHome} />
                </div>
                <Button text="Confirm" onClick={goToConfirm} />
            </ButtonContainer>
        </Content>
    </MainContainer>;
};

export default Appointment;
