import { useNavigate } from "react-router-dom";
import Button from "../Components/Button";
import MainContainer from "../Components/MainContainer";

const Home = () => {
    const navigate = useNavigate();

    const goToDetails = () => {
        navigate("/Details");
    };

    return <MainContainer>
        <Button text="Check in" size="xl" onClick={goToDetails} />
    </MainContainer>;
};

export default Home;
