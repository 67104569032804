import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import './App.css';
import Appointment from "./Pages/Appointment";
import Confirm from "./Pages/Confirm";
import Details from "./Pages/Details";
import Home from "./Pages/Home";

const App = () => {
    return <Router>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/Details" element={<Details />} />
            <Route path="/Appointment" element={<Appointment />} />
            <Route path="/COnfirm" element={<Confirm />} />
        </Routes>
    </Router>;
};

export default App;
