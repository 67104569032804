import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "../Components/Button";
import Content from "../Components/Content";
import MainContainer from "../Components/MainContainer";
import SecondaryButton from "../Components/SecondaryButton";
import Title from "../Components/Title";

const InputContainer = styled.div`
    margin-bottom: 32px;
    display: flex;
    flex-direction: row;
    & label {
        width: 240px;
        font-size: 2rem;
    }
    & input {
        font-size: 2rem;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 32px;
    & div {
        width: 240px;
    }
`;

const Details = () => {
    const navigate = useNavigate();

    const goToHome = () => {
        navigate("/Home");
    };

    const goToAppointment = () => {
        navigate("/Appointment");
    };

    return <MainContainer>
        <Content>
            <Title>Please enter your details</Title>
            <InputContainer>
                <label htmlFor="surname">Surname</label>
                <input type="text" id="surname" size={20} />
            </InputContainer>
            <InputContainer>
                <label htmlFor="dob-day">Date of birth</label>
                <input type="text" id="dob-day" placeholder="dd" size={1} />
                <input type="text" id="dob-month" placeholder="mm" size={1} />
                <input type="text" id="dob-year" placeholder="yyyy" size={2} />
            </InputContainer>
            <ButtonContainer>
                <div>
                    <SecondaryButton text="Cancel" onClick={goToHome} />
                </div>
                <Button text="Confirm" onClick={goToAppointment} />
            </ButtonContainer>
        </Content>
    </MainContainer>;
};

export default Details;
