import styled from "styled-components";

type BUTTON_SIZE = "m" | "xl";

const BUTTON_STYLES = {
    m: {
        borderRadius: "10px",
        fontSize: "1.5rem",
        padding: "8px 0"
    },
    xl: {
        borderRadius: "20px",
        fontSize: "2rem",
        padding: "32px 128px"
    }
};

interface IProps {
    onClick: () => void,
    size?: BUTTON_SIZE,
    text: string
};

interface IStyleProps {
    size: BUTTON_SIZE
};

const StyledButton = styled.button`
    background: none;
    border: 0 none;
    color: #ffffff;
    cursor: pointer;
    font-size: ${(props: IStyleProps) => BUTTON_STYLES[props.size].fontSize};
    padding: ${(props: IStyleProps) => BUTTON_STYLES[props.size].padding};
`;

const SecondaryButton = (props: IProps) => {
    return <StyledButton size={props.size || "m"} onClick={props.onClick}>
        {props.text}
    </StyledButton>;
};

export default SecondaryButton;