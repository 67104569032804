import styled from "styled-components";

const MainContainer = styled.main`
    background: #235ca3;
    flex: 1 1 auto;
    padding: 128px;
    justify-content: center;
    display: flex;
    align-items: center;
`;

export default MainContainer;