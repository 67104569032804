import styled from "styled-components";
import Content from "../Components/Content";
import MainContainer from "../Components/MainContainer";
import Title from "../Components/Title";

const ConfirmText = styled.p`
    font-size: 2rem;
    margin: 32px 0 0;
`;

const Confirm = () => {

    return <MainContainer>
        <Content>
            <Title>Thank you, our staff have been notified that you have arrived.</Title>
            <ConfirmText>Please take a seat.</ConfirmText>
        </Content>
    </MainContainer>;
};

export default Confirm;
